<template>

  <div class="buttons">
    <slot>
      
    </slot>
  </div>
  
</template>

<script>
 

export default {  
  props:[    
      
  ], 
  data () {
    return {    
      
    }
  },
  created() {    
    
  },
  computed: { 
  },
  methods: {    
  
  }
}
</script>

<style scoped>

.buttons {
  display: flex;
  flex-wrap: wrap;  
  gap: 5px;
} 
 
</style>
